import TitleText from '../../lib/components/design_system/typography/TitleText';
import Flex from '../../lib/components/layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export default function NotFoundPageContent(): JSX.Element {
  return (
    <Flex
      sx={css({
        justifyContent: 'center',
        height: 500,
      })}
    >
      <TitleText size="XXL">404</TitleText>
    </Flex>
  );
}
