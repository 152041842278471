import type {Logger} from './logger';

export default class ConsoleLogger implements Logger {
  constructor() {}
  logInfo(info: string): void {
    console.log(info);
  }

  logError(err: unknown): void {
    console.error(err);
  }
}
