import {
  PathSpec,
  getPathGenerator,
  getPathGeneratorWithParams,
} from './getPathGenerator';

type InternalPathSpec = PathSpec<'/intern/group-session'>;

type ValidAppPathSpec =
  | InternalPathSpec
  | PathSpec<'/'>
  | PathSpec<'/login'>
  | PathSpec<'/signup'>
  | PathSpec<'/calendar'>
  | PathSpec<'/calendar/connections'>
  | PathSpec<'/reset-password'>
  | PathSpec<'/discover'>
  | PathSpec<'/schedule'>
  | PathSpec<'/session'>
  | PathSpec<'/notification'>
  | PathSpec<'/message'>
  | PathSpec<'/my-profile'>
  | PathSpec<'/profile/wizard'>
  | PathSpec<'/profile/wizard/experience'>
  | PathSpec<'/profile/wizard/experience/education'>
  | PathSpec<'/profile/wizard/experience/profession'>
  | PathSpec<'/profile/wizard/biography'>
  | PathSpec<'/profile/wizard/goal'>
  | PathSpec<'/profile/wizard-done'>
  | PathSpec<'/mentorship/confirmed'>
  | PathSpec<'/mentorship/pending'>
  | PathSpec<'/mentorship/history'>;

export const linkToApp = getPathGenerator<ValidAppPathSpec>('/app');

type ValidAppPathSpecWithParams =
  | PathSpec<'/member/:id', {id: string}>
  | PathSpec<'/mentorship/request/:id', {id: string}>
  | PathSpec<'/event/:id', {id: string}>;

export const linkToAppWithParams =
  getPathGeneratorWithParams<ValidAppPathSpecWithParams>('/app');
