import useAppContext from './useAppContext';
import createAuthApolloClient from '../../apollo/utils/createApolloClient';
import useLoggerContext from '../../hooks/useLoggerContext';

import {ApolloProvider} from '@apollo/client';
import React, {useMemo} from 'react';

type Props = Readonly<{
  children?: React.ReactNode,
}>;

export default function ApolloClientProvider(props: Props): JSX.Element {
  const {children} = props;
  const {graphqlUrl, initialQueryState} = useAppContext();
  const {logger: getLogger} = useLoggerContext();

  const client = useMemo(() => {
    return createAuthApolloClient(graphqlUrl, initialQueryState, getLogger);
  }, [getLogger, graphqlUrl, initialQueryState]);

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
}