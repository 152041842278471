import CSRAppRoot from '../lib/components/PageRoot/CSRAppRoot';
import {initI18next} from '../lib/i18n';

import {loadableReady} from '@loadable/component';
import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';

async function init(): Promise<void> {
  const i18next = await initI18next();
  ReactDOM.hydrateRoot(
    document.getElementById('root') as Element,
    <StrictMode>
      <CSRAppRoot i18n={i18next} />
    </StrictMode>,
  );
}

void loadableReady(init);
