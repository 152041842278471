import ConsoleLogger from './ConsoleLogger';
import {Logger} from './logger';


import React from 'react';

export interface LoggerContextValue {
  readonly logger: Logger;
}

export default React.createContext<LoggerContextValue>({
  logger: new ConsoleLogger(),
});