import Optional from '../../../server/lib/Optional';
import isNonEmptyString from '../../isNonEmptyString';
import {ApolloLink, FetchResult, NextLink, Observable, Operation} from '@apollo/client';

export const AUTH_TOKEN_PAYLOAD_EXTENSION_KEY = 'authToken';

export default class AuthApolloLink extends ApolloLink {
  private bearerToken: string | null;
  constructor() {
    super();
    this.bearerToken = null;
  }

  request(operation: Operation, forward?: NextLink): Observable<FetchResult> | null {
    if (forward === undefined) {
      return null;
    }

    if (isNonEmptyString(this.bearerToken)) {
      const ctx = operation.getContext();
      const {headers} = ctx;
      operation.setContext({
        headers: {
          ...headers,
          Authorization: `Bearer ${this.bearerToken}`,
        } as Record<string, unknown>,
        ...ctx,
      });
    }

    return forward(operation).map((data) => {
      const authToken = Optional.of(data.extensions?.[AUTH_TOKEN_PAYLOAD_EXTENSION_KEY])
        .checkType(isNonEmptyString)
        .get();
      if ('extensions' in data && data.extensions != null && authToken != null) {
        this.bearerToken = authToken;
        delete data.extensions[AUTH_TOKEN_PAYLOAD_EXTENSION_KEY];
      }
      return data;
    });
  }
}
