import {initOptions as serverInitOptions} from '../../server/lib/i18n';

import i18next, {InitOptions, use} from 'i18next';
import I18nextBrowserLanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector';
import I18NextHttpBackend, {HttpBackendOptions} from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

interface CustomInitOptions extends InitOptions {
  backend: HttpBackendOptions;
  detection: DetectorOptions;
}

// Parse Date to minutes
const cookieMinutes =
  serverInitOptions.detection.cookieExpirationDate!.getTime() / (1000 * 60);

const initOptions: CustomInitOptions = {
  ...serverInitOptions,
  backend: {
    // Interpolation is set to {value}; otherwise, loadPath should use {{value}}.
    loadPath: '/static/locales/{lng}/{ns}.json',
  },
  detection: {
    ...serverInitOptions.detection,
    cookieMinutes,
  },
};

/**
 * Initialize and returns as i18n instance (client-side)
 */
export const initI18next = async () => {
  await use(I18NextHttpBackend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init(initOptions);

  return i18next;
};
