import ApolloClientProvider from './ApolloClientProvider';
import AppContext, {AppContextValue} from './AppContext';
import EmotionCacheProvider from './EmotionCacheProvider';
import {isAppContextValue} from './isAppContextValue';
import Routes from '../../../pages/Routes';
import AppThemeProvider from '../design_system/AppThemeProvider';
import {WindowScrollDisablerProvider} from '../layout/useWindowScrollDisabler';

import {i18n, Resource} from 'i18next';
import React from 'react';
import {I18nextProvider, useSSR} from 'react-i18next';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

const router = createBrowserRouter(Routes);

interface Props {
  i18n: i18n;
}

export default function CSRAppRoot(props: Props): JSX.Element {
  const {i18n} = props;
  const initialContextFromWindow =
    window.INITIAL_APP_CONTEXT as AppContextValue;
  const initialI18nStore =
    initialContextFromWindow.initialI18nStore as Resource;
  const initialLanguage = initialContextFromWindow.initialLanguage;

  useSSR(initialI18nStore, initialLanguage);

  if (!isAppContextValue(initialContextFromWindow)) {
    // TODO determine what to do when initial context is missing.
    return <div>Error</div>;
  }

  return (
    <WindowScrollDisablerProvider>
      <EmotionCacheProvider>
        <AppThemeProvider>
          <I18nextProvider i18n={i18n}>
            <AppContext.Provider value={initialContextFromWindow}>
              <ApolloClientProvider>
                <RouterProvider router={router} />
              </ApolloClientProvider>
            </AppContext.Provider>
          </I18nextProvider>
        </AppThemeProvider>
      </EmotionCacheProvider>
    </WindowScrollDisablerProvider>
  );
}
