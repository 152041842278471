const brown = {
  brown100: '#F4F1F2',
  brown200: '#ECE8EA',
  brown300: '#DAD1D5',
  brown400: '#C7BBC0',
  brown500: '#A28D95',
  brown600: '#866B75',
  brown700: '#6A4956',
  brown800: '#451B2B',
  brown900: '#431527',
  brown1000: '#380C1D',
};

export default brown;
