import {Resource} from 'i18next';
import React from 'react';

export type AppContextValue = Readonly<{
  graphqlUrl: string;
  initialI18nStore: Resource | null;
  initialLanguage: string;
  initialQueryState: object | null;
  statusCode?: number;
}>;

export default React.createContext<AppContextValue>({
  graphqlUrl: '',
  initialQueryState: null,
  initialI18nStore: null,
  initialLanguage: '',
});
