import ConfirmedReservations from './ConfirmedReservations';
import PendingReservations from './PendingReservations';
import RequestPage from './RequestPage';
import ReservationHistory from './ReservationHistory';
import TabsPage from './TabsPage';
import getSharedLoader from '../../../lib/router/getSharedLoader';
import NotFoundPageContent from '../../NotFound/NotFoundPageContent';

import React from 'react';
import {Route} from 'react-router-dom';

export default (
  <>
    <Route
      key="mentorship-root"
      path="mentorship/*"
    >
      <Route element={<TabsPage />}>
        <Route
          element={<ConfirmedReservations />}
          loader={getSharedLoader({
            loadableRoot: ConfirmedReservations,
          })}
          path="confirmed"
        />
        <Route
          element={<PendingReservations />}
          loader={getSharedLoader({
            loadableRoot: PendingReservations,
          })}
          path="pending"
        />
        <Route
          element={<ReservationHistory />}
          loader={getSharedLoader({
            loadableRoot: ReservationHistory,
          })}
          path="history"
        />
      </Route>
      <Route
        element={<RequestPage />}
        loader={getSharedLoader({
          loadableRoot: RequestPage,
        })}
        path="request/:id"
      />
      <Route
        element={<NotFoundPageContent />}
        index={true}
        path="*"
      />
    </Route>
  </>
);
