import GlobalStyles from './GlobalStyles';
import {Theme, defaultTheme} from './theme';

import {ThemeProvider as ThemeProviderImpl} from '@emotion/react';
import React from 'react';

export type Props = Readonly<{
  children?: React.ReactNode,
  theme?: Theme,
}>;

export default function AppThemeProvider(props: Props): JSX.Element {
  const {children, theme} = props;
  return (
    <ThemeProviderImpl theme={theme ?? defaultTheme}>
      <GlobalStyles />
      {children}
    </ThemeProviderImpl>
  );
}