import {BaseTextProps} from './options';

export default function useSharedHTMLTextProps(
  props: BaseTextProps,
): React.HTMLAttributes<HTMLElement> {
  const {id} = props;
  return {
    id,
  };
}
