import ConfirmEmailPage from './ConfirmEmailPage';
import ConfirmResetPassword from './ConfirmResetPassword';
import Intern from './Intern';
import LoginPage from './LoginPage';
import MyPage from './MyPage';
import MyPageRoutes from './MyPage/MyPageRoutes';
import ResetPassword from './ResetPassword';
import SignupPage from './SignupPage';
import getSharedLoader from '../lib/router/getSharedLoader';

import React from 'react';
import {Route} from 'react-router-dom';

export default (
  <>
    <Route
      element={<SignupPage />}
      key="signup"
      loader={getSharedLoader({
        loadableRoot: SignupPage,
      })}
      path="signup"
    />
    <Route
      element={<LoginPage />}
      key="login"
      loader={getSharedLoader({
        loadableRoot: LoginPage,
      })}
      path="login"
    />
    <Route
      element={<Intern />}
      key="intern"
      loader={getSharedLoader({
        loadableRoot: Intern,
      })}
      path="intern/*"
    />
    <Route
      element={<ResetPassword />}
      key="reset-password"
      loader={getSharedLoader({
        loadableRoot: ResetPassword,
      })}
      path="reset-password"
    />
    <Route
      element={<ConfirmResetPassword />}
      key="confirm-reset-password"
      loader={getSharedLoader({
        loadableRoot: ConfirmResetPassword,
      })}
      path="confirm-reset-password"
    />
    <Route
      element={<ConfirmEmailPage />}
      key="confirm-email-signup"
      loader={getSharedLoader({
        loadableRoot: ConfirmEmailPage,
      })}
      path="confirm-email-signup"
    />
    <Route
      element={<MyPage />}
      key="mypage"
      loader={getSharedLoader({
        loadableRoot: MyPage,
      })}
    >
      {MyPageRoutes}
    </Route>
  </>
);
