import i18next, {InitOptions, use, ReactOptions} from 'i18next';
import {
  LanguageDetector,
  LanguageDetectorOptions,
} from 'i18next-http-middleware';
import resourcesToBackend from 'i18next-resources-to-backend';
import {initReactI18next} from 'react-i18next';

export type SupportedLanguageKey = keyof typeof SUPPORTED_LANGUAGES;
/**
 * Supported i18next languages.
 * key: Label
 */
export const SUPPORTED_LANGUAGES = {
  'en-US': 'English',
  'ja-JP': '日本語',
} as const;

interface CustomLanguageDetectorOptions extends LanguageDetectorOptions {
  caches: string[] | undefined;
  lookupHeader: string;
  lookupHeaderRegex: RegExp;
}

interface CustomInitOptions extends InitOptions {
  detection: CustomLanguageDetectorOptions;
}

const detectionOptions: CustomLanguageDetectorOptions = {
  order: ['querystring', 'cookie', 'header'],
  lookupQuerystring: 'lng', // Accepts query string lng. E.g. localhost:3001?lng=en-US
  lookupHeader: 'accept-language', // Detects the user language
  lookupHeaderRegex: /(([a-z]{2})-?([A-Z]{2})?)\s*;?\s*(q=([0-9.]+))?/gi,
  caches: ['cookie'], // Use cookies to store
  cookieExpirationDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // Set to 30 days
  lookupCookie: 'i18next', // Cookie name
};

const reactOptions: ReactOptions = {
  useSuspense: false, // No need for suspense, since we are using SSR
  transSupportBasicHtmlNodes: true,
};

/**
 * This config needs to act like a contract between server and client to avoid hydration errors.
 */
export const initOptions: CustomInitOptions = {
  debug: false,
  ns: ['translation', 'data'],
  defaultNS: 'translation',
  preload: ['en-US', 'ja-JP'],
  supportedLngs: Object.keys(SUPPORTED_LANGUAGES),
  fallbackLng: 'ja-JP',
  load: 'currentOnly', // Loads only languages codes like ja-JP / en-US
  detection: detectionOptions,
  react: reactOptions,
  interpolation: {
    // Set to {value} to match the current translation file format.
    // This might cause issues in the future since single curly braces could be used someday.
    prefix: '{',
    suffix: '}',
  },
};

const resources = resourcesToBackend(
  (language: string, namespace: string) =>
    import(`../../../../public/locales/${language}/${namespace}.json`),
);

/**
 * Initialize and returns as i18n instance (server-side)
 */
export const initI18next = async () => {
  await use(resources)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(initOptions);

  return i18next;
};
