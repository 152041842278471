export const defaultBreakpoints = {
  l: 1024,

  md: 768,

  sm: 480,

  xl: 1600,
};

export type Breakpoints = typeof defaultBreakpoints;
