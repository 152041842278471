import {
  URLSearchParamsInit,
  createSearchParams,
  generatePath,
} from 'react-router-dom';

export type PathSpec<
  T extends string,
  TParams extends Maybe<Record<string, string | number | boolean>> = undefined,
> = {
  readonly params: TParams;
  readonly path: T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PathSpecWithoutParams<T extends PathSpec<string, any>> = Extract<
  T,
  {
    params: undefined;
  }
>;

export type PathSpecParams<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends PathSpec<string, any>,
  TKey extends T['path'],
> = Extract<T, {path: TKey}>['params'];

export type ValidPrefix = '' | '/app';

export type PathGenerator<T> = (
  path: T,
  search?: URLSearchParamsInit,
) => string;

function appendSearch(
  base: string,
  search: Maybe<URLSearchParamsInit>,
): string {
  if (search != null) {
    return base + `?${createSearchParams(search).toString()}`;
  }
  return base;
}

export function getPathGenerator<TPathSpec extends PathSpec<string, undefined>>(
  pathPrefix: ValidPrefix,
): PathGenerator<TPathSpec['path']> {
  return <T extends PathSpecWithoutParams<TPathSpec>['path']>(
    path: T,
    search?: URLSearchParamsInit,
  ): string => {
    const result = generatePath(`${pathPrefix}${path}`);
    return appendSearch(result, search);
  };
}

export type PathGeneratorWithParams<T, TParam> = (
  path: T,
  params: TParam,
  search?: URLSearchParamsInit,
) => string;

export function getPathGeneratorWithParams<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TPathSpec extends PathSpec<string, any>,
>(
  pathPrefix: ValidPrefix,
): PathGeneratorWithParams<
  TPathSpec['path'],
  PathSpecParams<TPathSpec, TPathSpec['path']>
> {
  return <T extends TPathSpec['path']>(
    path: T,
    params: PathSpecParams<TPathSpec, T>,
    search?: URLSearchParamsInit,
  ): string => {
    const result = generatePath(`${pathPrefix}${path}`, params);
    return appendSearch(result, search);
  };
}
