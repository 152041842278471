export const defaultSizes = {
  maxWidth: 1600,

  sm: {
    pageContentSidePadding: 0,
    pageSidePadding: 8,
    topNavHeight: 48,
  },

  md: {
    pageContentSidePadding: 0,
    pageSidePadding: 16,
    topNavHeight: 48,
  },

  // Large width, e.g. desktop
  l: {
    pageContentSidePadding: 116,
    pageSidePadding: 96,
    topNavHeight: 72,
  },
};

export type Sizes = typeof defaultSizes;
