import * as Immutable from 'immutable';
import React, {useCallback, useContext, useMemo, useState} from 'react';

export interface WindowScrollDisablerContextValue {
  addKey: (key: string) => void;
  removeKey: (key: string) => void;
}

export const WindowScrollDisablerContext: React.Context<WindowScrollDisablerContextValue> =
  React.createContext<WindowScrollDisablerContextValue>({
    addKey: () => {},
    removeKey: () => {},
  });

export interface Props {
  readonly children: React.ReactNode;
}

export function WindowScrollDisablerProvider(props: Props): JSX.Element {
  const {children} = props;
  const [_, setDisablerKeys] = useState(Immutable.Set<string>());

  const updateWindowState = useCallback(
    (currentKeys: Immutable.Set<string>) => {
      if (currentKeys.size > 0) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    },
    [],
  );

  const addKey = useCallback(
    (key: string) => {
      setDisablerKeys((oldDisablerKeys) => {
        let result = oldDisablerKeys;
        if (!oldDisablerKeys.has(key)) {
          result = oldDisablerKeys.add(key);
          updateWindowState(result);
        }

        return result;
      });
    },
    [updateWindowState],
  );
  const removeKey = useCallback(
    (key: string) => {
      setDisablerKeys((oldDisablerKeys) => {
        let result = oldDisablerKeys;
        if (oldDisablerKeys.has(key)) {
          result = oldDisablerKeys.remove(key);
          updateWindowState(result);
        }

        return result;
      });
    },
    [updateWindowState],
  );
  const value = useMemo(() => {
    return {
      addKey,
      removeKey,
    };
  }, [addKey, removeKey]);

  return (
    <WindowScrollDisablerContext.Provider value={value}>
      {children}
    </WindowScrollDisablerContext.Provider>
  );
}

export default function useWindowScrollDiabler(): WindowScrollDisablerContextValue {
  return useContext(WindowScrollDisablerContext);
}
