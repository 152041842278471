import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import React from 'react';

const CACHE_KEY = 'app';

export type Props = Readonly<{
  children?: React.ReactNode,
}>;

export default function EmotionCacheProvider(props: Props): JSX.Element {
  const {children} = props;
  const emotionCache = createCache({
    key: CACHE_KEY,
  });

  return (
    <CacheProvider value={emotionCache}>
      {children}
    </CacheProvider>
  );
}